.w-10 {
  width: 40pt !important;
}

.btn {
  --bs-btn-bg: #f47921 !important;
    --bs-btn-color: white !important;
}

.text-p {
  text-align: center;
    max-width: 300pt;
    margin: 0px auto;
    color: grey;
    font-size: 12pt;
    line-height: 1;
}

.tabs_selector {
  display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 20pt 0;
    gap: 10pt;
    .tab_selector {
      text-align: center;
      padding: 6pt 10pt;
      font-size: 12pt;
      color: grey;
      cursor: pointer;
      border: 1pt solid #f47921;
      border-radius: 10pt;
      &.active {
        background: #f47921;
        color: white;
      }
    }
}

.tickets {
  .ticket {
    display: flex;
    background: #E6E7E9;
    border-radius: 10pt;
    max-width: 400pt;
    cursor: pointer;
    margin-bottom: 20pt;
    .ticket_img {
      max-width: 190pt;
      padding: 10pt;
      img {
        width: auto;
        height: 7rem;
        border-radius: 10pt 0 0 10pt;
      }
    }
    .ticket_info {
      padding: 10pt;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        color: black;
        font-size: 17pt;
        margin-bottom: 10pt;
      }
      .i {
        color: grey;
        font-size: 12pt;
      }
    }
    &:hover {
      background: #9e9e9e;
      .ticket_info {
        h3 {
          color: white;
        }
        .i {
          color: white;
        }
      }
    }
  }
}



// big desktop
@media only screen and (min-width: 1920px) {}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {}

//Laptop
@media only screen and (min-width: 1200px) and (max-width: 1600px) {}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {}

// big tablet LG
@media (max-width: 1200px) {}

// tablet MD
@media only screen and (max-width: 768px) and (orientation: portrait) {}

//landscape mobile
@media only screen and (max-width: 768px) and (orientation: landscape)  {}

@media (min-width: 577px) and (max-width: 767px) {}
// only mobile
@media (max-width: 576px) {
  .tabs_selector {
      margin: 2rem 0;
      gap: 0.5rem;
      .tab_selector {
        padding: 0.6rem 0.6rem;
        font-size: 1rem;
        border-radius: 10px;
        white-space: nowrap;
        overflow: hidden;
      }
  }
}